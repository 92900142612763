import React, { useState, useEffect } from 'react';
import './successPayment.scss';
import Avatar from 'react-avatar';
import logo from '../old/assets/images/logo/logo-morphing-white.gif';
import { navigate } from 'gatsby';
import { getCheckoutSession, getArtistByPaymentSessionId } from '../old/utils/api';
import { DASHBOARD } from '../router/routes/index';

const SuccessPayment = () => {
	const [session, setSession] = useState({});
	const [artistName, setArtistName] = useState('');
	const [artistPic, setArtistPic] = useState(null);
	const [sessionId, setSessionId] = useState('');

	const getArtist = () => {
		getArtistByPaymentSessionId({ id: sessionId }).then((result) => {
			setArtistName(result.fullname);
			setArtistPic(result.picture);
		});
	};

	useEffect(() => {
		if (sessionId !== '') {
			async function fetchSession() {
				getCheckoutSession('/checkout-session?sessionId=' + sessionId).then((res) => setSession(res));
			}
			fetchSession();
			getArtist();
		}
	}, [sessionId]);

	useEffect(() => {
		setSessionId(new URLSearchParams(document.location.search.substring(1)).get('session_id'));
	}, []);

	return (
		<div className="succesPaymentComponent">
			<img alt="quan" className="succesPaymentComponent_logo" src={logo} />
			<div className="succesPaymentComponent_wrapper">
				<Avatar size="100" round={true} src={artistPic} className="succesPaymentComponent_wrapper_avatar" />
				<h3>{artistName} has received your question!</h3>
				<p className="t3">
					{artistName} will get back to you within <text>5 days</text>. We'll notify you when your answer is ready!
				</p>
				<div className="button" onClick={() => navigate(DASHBOARD)}>
					<p>CONTINUE BROWSING</p>
				</div>
			</div>
		</div>
	);
};

export default SuccessPayment;
